<template>
  <gw-dialog v-model="dialog" @close:dialog="onClose" @open:dialog="onOpen">
    <template #activator="{ on }">
      <v-btn :loading="loading" class="mr-4" color="primary" v-on="on">
        {{ $t("export") }}
      </v-btn>
    </template>

    <template #prepend-actions>
      <v-btn :loading="loading" color="primary" text @click="onExport">
        {{ $t("export") }}
      </v-btn>
    </template>

    <v-form v-if="dialog" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="loading"
        hide-bottom-actions
        hide-top-actions
        no-nest
        @cancel="onClose"
        @save="onExport"
      >
        <v-row>
          <v-col cols="12" lg="6">
            <v-select
              v-model="sBookType"
              :items="bookOptions"
              :label="$t('invoices')"
              dense
              flat
              hide-details
              outlined
              @change="onBookSelect"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <date-range
              :select-types="arDateTypes"
              :value="dateRange"
              @input="onSetDates"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <v-switch
              v-model="obFilters.delete_exists"
              :hint="$t('accounting.export.delete.exists.info')"
              :label="$t('accounting.export.delete.exists')"
              persistent-hint
            />
          </v-col>
        </v-row>
      </gw-form-observer>
    </v-form>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DateRange from "@/components/form/fields/DateRange.vue";
import GwDialog from "@/components/common/GwDialog.vue";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";
import { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { AccountingEntryInvoice } from "@planetadeleste/vue-mc-gw";
import Utils from "@/services/Utils";

interface AccountingEntryInvoiceExportResponse {
  filename: string;
  content: string;
}

@Component({
  components: { AccountingMovementTypeSelect, GwDialog, DateRange },
})
export default class AccountingEntryInvoiceExport extends Vue {
  dialog = false;
  loading = false;
  obFilters: Record<string, any> = {};
  dateRange: string[] = [];
  sBookType: string = "all";

  get bookOptions() {
    return [
      { text: this.$t("all"), value: "all" },
      { text: this.$t("emitted"), value: "emitted" },
      { text: this.$t("received"), value: "received" },
    ];
  }

  get arDateTypes() {
    return [
      "current_month",
      "last_month",
      "current_year",
      "last_year",
      "custom",
    ];
  }

  onSetDates(arValue: string[]) {
    this.dateRange = arValue;
    this.$set(this.obFilters, "created_at", arValue);
  }

  onBookSelect(sValue: string) {
    let sBook: string[] | null = null;

    if (sValue === "emitted") {
      sBook = ["I", "V"];
    } else if (sValue === "received") {
      sBook = ["E", "C"];
    }

    this.$set(this.obFilters, "book", sBook);
  }

  onClose() {
    this.dialog = false;
    this.obFilters = {};
  }

  onOpen() {
    this.obFilters = {
      delete_exists: true,
    };
  }

  async onExport() {
    this.loading = true;
    const obConfig: AxiosRequestConfig = {
      url: route("accountingentryinvoices.export"),
      method: "POST",
      data: {
        filters: this.obFilters,
      },
    };

    const obModel = new AccountingEntryInvoice();
    const obResponse = await obModel.createRequest(obConfig).send();
    const sRespMessage = obResponse.getData().message;
    this.loading = false;

    if (sRespMessage) {
      this.$toast(sRespMessage);
    }

    const obData = obResponse.getData()
      .data as AccountingEntryInvoiceExportResponse;
    Utils.downloadBlob(obData.content, obData.filename);
  }
}
</script>
